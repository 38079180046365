import { ArrowBack, DoneAll, Phone, PhonePaused } from "@mui/icons-material"
import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material"
import { UpdateLeadRequest } from "api/leads/updateLead"
import Loader from "components/commun/Loader"
import QualificationChip from "components/commun/QualificationChip"
import { MODE, QUALIFICATIONS, SEXE } from "constants/data"
import { URL_DASHBOARD } from "constants/urls"
import format from "date-fns/format"
import useVillesQuery from "hooks/queries/data/useVillesQuery"
import useLeadQuery from "hooks/queries/leads/useLeadQuery"
import useUpdateLeadMutation from "hooks/queries/leads/useUpdateLeadMutation"
import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useStopwatch } from "react-timer-hook"
import { useDocumentTitle } from "usehooks-ts"

type FormValues = {
  qualification: { id: string, name: string} | null
  isQualified: boolean
  ville: { id: number, name: string} | null
  sexe: { id: string, name: string} | null
  debutAppel: string
  finAppel: string
  entreprise: string
  fonction: string
  modeFinancement: { id: string, name: string} | null
  montantAvance: string
  superficieSouhaite: string
  nombreChambre: string
  commentaire: string
  nouvelle_reponse: string
}

export default function Lead() {

  useDocumentTitle("Digital Leads | Détails du lead")

  const params = useParams()
  const navigate = useNavigate()

  const { register, handleSubmit, control, setValue, watch } = useForm<FormValues>()

  const [activeTab, setActiveTab] = React.useState(1)

  const timer = useStopwatch()

  const leadQuery = useLeadQuery({
    key: {
      id: Number(params.id)
    }
  })

  const villesQuery = useVillesQuery()

  // Register form fields
  React.useEffect(() => {
    register("qualification", {
      required: "Champs requis",
      validate: value => value?.id !== "indefini" || "Veuillez choisir une qualification"
    })
    register("ville")
    register("sexe")
    register("debutAppel")
    register("finAppel")
    register("entreprise")
    register("fonction")
    register("modeFinancement")
    register("montantAvance", {
      validate: value => {
        if (watch("modeFinancement.id") === "a credit" && Number(value) <= 0) {
          return "Montant avance doit etre superieur a 0"
        }
      }
    })
    register("superficieSouhaite")
    register("nombreChambre")
    register("commentaire")
    register("nouvelle_reponse")
  }, [register])

  // Set form fields values from query data
  React.useEffect(() => {
    if (leadQuery.data) {
      setValue("qualification", QUALIFICATIONS.find(item => item.id === leadQuery.data.qualification) || null)
      setValue("isQualified", leadQuery.data.is_qualified)
      setValue("ville", leadQuery.data.ville)
      setValue("sexe", SEXE.find(item => item.id === leadQuery.data.sexe) || null)
      setValue("debutAppel", leadQuery.data.heur_debut_appel ? createTimeFromDate(leadQuery.data.heur_debut_appel) : "")
      setValue("finAppel", leadQuery.data.heur_fin_appel ? createTimeFromDate(leadQuery.data.heur_fin_appel) : "")
      setValue("entreprise", leadQuery.data.entreprise || "")
      setValue("fonction", leadQuery.data.fonction || "")
      setValue("modeFinancement", MODE.find(item => item.id === leadQuery.data.mode_financement) || null)
      setValue("montantAvance", leadQuery.data.montat_avance || "")
      setValue("superficieSouhaite", leadQuery.data.superficie_souhaite || "")
      setValue("nombreChambre", leadQuery.data.nombre_chambre?.toString() || "")
      setValue("commentaire", leadQuery.data.commentaire || "")
      setValue("nouvelle_reponse", leadQuery.data.choice?.answer || "")
    }
  }, [leadQuery.data])

  const updateLeadMutation = useUpdateLeadMutation()

  function toggleTimer() {
    if (timer.isRunning) {
      timer.reset(undefined, false)
      setValue("finAppel", format(new Date(), "HH:mm:ss"))
    } else {
      timer.start()
      setValue("debutAppel", format(new Date(), "HH:mm:ss"))
    }
  }

  function createDateFromTime(time: string) {
    if (!time) return null
    const [hours, minutes, seconds] = time.split(":")
    const date = new Date()
    date.setHours(Number(hours))
    date.setMinutes(Number(minutes))
    date.setSeconds(Number(seconds))
    return date.toISOString()
  }

  function createTimeFromDate(date: string) {
    if (!date) return ""
    const d = new Date(date)
    return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
  }

  const updateLead: SubmitHandler<FormValues> = (data) => {
    const mutationData: UpdateLeadRequest = {
      id: Number(params.id),
      qualification: data.qualification?.id,
      is_qualified: data.isQualified,
      ville: data.ville?.id,
      sexe: data.sexe?.id,
      heur_debut_appel: createDateFromTime(data.debutAppel) || null,
      heur_fin_appel: createDateFromTime(data.finAppel) || null,
      entreprise: data.entreprise,
      fonction: data.fonction,
      mode_financement: data.modeFinancement?.id,
      montat_avance: Number(data.montantAvance) || undefined,
      superficie_souhaite: Number(data.superficieSouhaite) || undefined,
      nombre_chambre: Number(data.nombreChambre) || undefined,
      commentaire: data.commentaire,
      nouvelle_reponse: data.nouvelle_reponse
    }

    updateLeadMutation.mutate(mutationData)
  }

  if (leadQuery.isLoading) return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: 300 }}>
      <Loader />
    </Stack>
  )

  if (leadQuery.isError) return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: 300, width: "100%" }}>
      <Alert severity="error">Une erreur est survenue</Alert>
    </Stack>
  )

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
        <Button
          color="info"
          onClick={() => navigate(URL_DASHBOARD)}
          startIcon={<ArrowBack />}
          sx={{ padding: 0, "&:hover": { backgroundColor: "transparent" } }}
        >
          Retour
        </Button>

        <Button
          variant="contained"
          color={timer.isRunning ? "info" : "success"}
          startIcon={timer.isRunning ? <PhonePaused /> : <Phone />}
          onClick={toggleTimer}
          sx={{ borderRadius: 1000, px: 6, fontSize: 14 }}
        >
          {timer.hours.toString().padStart(2, "0")}:{timer.minutes.toString().padStart(2, "0")}:{timer.seconds.toString().padStart(2, "0")}
        </Button>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Tabs
            component={Paper}
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Informations generales" value={1} />
            <Tab label="Social media" value={2} />
            <Tab label="Web" value={3} />
            <Tab label="Qualification" value={4} />
          </Tabs>
        </Grid>

        <Grid item xs={12} md={9}>
          <form onSubmit={handleSubmit(updateLead)}>
            <Box component={Paper} role="tabpanel" hidden={activeTab !== 1}>
              {activeTab === 1 && (
                <Box p={8}>
                  <Typography variant="h6" textAlign="center" mb={8}>Informations generales</Typography>

                  <Grid container spacing={8}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nom"
                        variant="outlined"
                        value={leadQuery.data.full_name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        value={leadQuery.data.email}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Telephone"
                        variant="outlined"
                        value={leadQuery.data.phone_number}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        value={leadQuery.data.message || ""}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Question"
                        variant="outlined"
                        value={leadQuery.data.choice?.question || ""}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="nouvelle_reponse"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Reponse"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            <Box component={Paper} role="tabpanel" hidden={activeTab !== 2}>
              {activeTab === 2 && (
                <Box p={8}>
                  <Typography variant="h6" textAlign="center" mb={8}>Social media</Typography>

                  <Grid container spacing={8}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Campagne"
                        variant="outlined"
                        value={leadQuery.data.campagne?.name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Formulaire id"
                        variant="outlined"
                        value={leadQuery.data.campagne?.name}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            <Box component={Paper} role="tabpanel" hidden={activeTab !== 3}>
              {activeTab === 3 && (
                <Box p={8}>
                  <Typography variant="h6" textAlign="center" mb={8}>Media</Typography>

                  <Grid container spacing={8}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="utm_medium"
                        variant="outlined"
                        value={leadQuery.data.utm_medium || ""}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="utm_source"
                        variant="outlined"
                        value={leadQuery.data.utm_source || ""}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="utm_campaign"
                        variant="outlined"
                        value={leadQuery.data.utm_campaign || ""}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            <Box component={Paper} role="tabpanel" hidden={activeTab !== 4}>
              {activeTab === 4 && (
                <Box p={8}>
                  <Typography variant="h6" textAlign="center" mb={8}>Qualification</Typography>
                  <Grid container spacing={8}>
                    <Grid item md={6} xs={12}>
                      <Controller
                        name="qualification"
                        control={control}
                        render={ctx => (
                          <Autocomplete
                            {...ctx.field}
                            fullWidth
                            options={QUALIFICATIONS}
                            value={ctx.field.value}
                            onChange={(_, data) => ctx.field.onChange(data)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <QualificationChip qualification={option.id as Qualification} />
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                fullWidth
                                margin="none"
                                type="text"
                                label="Qualification"
                                error={!!ctx.fieldState.error}
                                helperText={ctx.fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>

                    {villesQuery.isSuccess && (
                      <Grid item md={6} xs={12}>
                        <Controller
                          name="ville"
                          control={control}
                          render={ctx => (
                            <Autocomplete
                              {...ctx.field}
                              fullWidth
                              options={villesQuery.data}
                              value={ctx.field.value}
                              onChange={(_, data) => ctx.field.onChange(data)}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  margin="none"
                                  type="text"
                                  label="Ville"
                                  error={!!ctx.fieldState.error}
                                  helperText={ctx.fieldState.error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="sexe"
                        control={control}
                        render={ctx => (
                          <Autocomplete
                            {...ctx.field}
                            fullWidth
                            options={SEXE}
                            value={ctx.field.value}
                            onChange={(_, data) => ctx.field.onChange(data)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                fullWidth
                                margin="none"
                                type="text"
                                label="Sexe"
                                error={!!ctx.fieldState.error}
                                helperText={ctx.fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="debutAppel"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Heure debut appel"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="finAppel"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Heure fin appel"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="entreprise"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Entreprise"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="fonction"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Fonction"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="modeFinancement"
                        control={control}
                        render={ctx => (
                          <Autocomplete
                            {...ctx.field}
                            fullWidth
                            options={MODE}
                            value={ctx.field.value}
                            onChange={(_, data) => ctx.field.onChange(data)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                fullWidth
                                margin="none"
                                type="text"
                                label="Mode financement"
                                error={!!ctx.fieldState.error}
                                helperText={ctx.fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="montantAvance"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Montant avance"
                            variant="outlined"
                            type="number"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="superficieSouhaite"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Superficie souhaité"
                            variant="outlined"
                            type="number"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="nombreChambre"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            label="Nombre de chambres"
                            variant="outlined"
                            type="number"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="commentaire"
                        control={control}
                        render={ctx => (
                          <TextField
                            {...ctx.field}
                            fullWidth
                            multiline
                            rows={4}
                            label="Commentaire de télévendeuse"
                            variant="outlined"
                            error={!!ctx.fieldState.error}
                            helperText={ctx.fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="isQualified"
                        control={control}
                        render={ctx => (
                          <FormControlLabel
                            label="Marquer comme qualifié"
                            control={
                              <Checkbox
                                {...ctx.field}
                                checked={ctx.field.value}
                                onChange={(_, data) => ctx.field.onChange(data)}
                              />
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>

            <Stack direction="row" justifyContent="flex-end" mt={8} gap={4}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: 160, maxWidth: "100%" }}
                endIcon={<DoneAll />}
                type="submit"
              >
                Enregistrer
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}
