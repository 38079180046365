import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import useUserQuery from "./queries/auth/useUserQuery"
import { COOKIE_ACCESS_TOKEN, COOKIE_CLIENT_ID, COOKIE_CLIENT_SECRET, COOKIE_REFRESH_TOKEN } from "constants/cookies"
import { useQueryClient } from "@tanstack/react-query"
import toast from "react-hot-toast"
import { UserDetailsResponse } from "api/auth/userDetails"

type Output = {
  isLoading: boolean,
  state: boolean | undefined,
  user: UserDetailsResponse | undefined,
  logout: () => void
}

/**
 * A custom React hook that manages the authentication state of the user.
 * @returns An object containing the current authentication state and a boolean indicating whether the user query is initialy loading.
 */
export default function useAuth(): Output {

  const queryClient = useQueryClient()
  const userQuery = useUserQuery()

  const [auth, setAuth] = useState<boolean>()

  const [{ "access-token": accessToken }, , removeCookie] = useCookies([
    COOKIE_ACCESS_TOKEN,
    COOKIE_REFRESH_TOKEN,
    COOKIE_CLIENT_ID,
    COOKIE_CLIENT_SECRET
  ])

  // Remove the user query if no access token is found
  useEffect(() => {
    if (!accessToken || userQuery.isError) {
      userQuery.remove()
    }
  }, [accessToken, userQuery.isError])

  // Check if the user is authenticated
  useEffect(() => {
    if (userQuery.data && accessToken && userQuery.fetchStatus !== "fetching") {
      setAuth(true)
    }

    else if (!userQuery.data && !accessToken && userQuery.fetchStatus !== "fetching") {
      setAuth(false)
    }

    else if (userQuery.isError) {
      setAuth(false)
    }

    else setAuth(undefined)
  }, [accessToken, userQuery.data, userQuery.fetchStatus, userQuery.isError])

  async function logout() {
    // Cancel all pending queries if any
    await queryClient.cancelQueries({
      type: "all"
    })

    // Remove all queries from cache
    queryClient.removeQueries({
      type: "all"
    })

    removeCookie(COOKIE_ACCESS_TOKEN, { path: "/" })
    removeCookie(COOKIE_REFRESH_TOKEN, { path: "/" })
    removeCookie(COOKIE_CLIENT_ID, { path: "/" })
    removeCookie(COOKIE_CLIENT_SECRET, { path: "/" })

    toast.success("Vous êtes déconnecté", { id: "auth" })
  }

  console.log(auth)

  return {
    isLoading: userQuery.isInitialLoading,
    state: auth,
    user: userQuery.data,
    logout: logout,
  }
}
