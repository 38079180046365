import { Avatar, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useState } from "react"
import MainDialog from "components/commun/MainDialog"
import { LogoutOutlined as LogoutIcon } from "@mui/icons-material"
import { useBoolean } from "usehooks-ts"
import useAuth from "hooks/useAuth"

export default function User() {

  const auth = useAuth()

  const logoutDialogHandler = useBoolean(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  if (auth.isLoading) return null

  return (
    <div className="user">
      <Avatar
        variant="circular"
        alt="Ayoub Benabid"
        sx={{ cursor: "pointer" }}
        onClick={event => setAnchorEl(event.currentTarget)}
        src=""
      >
        <Typography color="black" fontSize={20}>
          {auth.user?.first_name && auth.user?.last_name ? (
            auth.user?.first_name[0].toUpperCase() + auth.user?.last_name[0].toUpperCase()
          ) : (
            auth.user?.username[0].toUpperCase()
          )}
        </Typography>
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
          setAnchorEl(null)
          logoutDialogHandler.setTrue()
        }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <LogoutIcon sx={{ mr: 2, fill: "#000000DE" }} />
            Se déconnecter
          </Stack>
        </MenuItem>
      </Menu>

      <MainDialog
        isOpen={logoutDialogHandler.value}
        closeFn={logoutDialogHandler.setFalse}
        title="Se déconnecter"
        content="Voulez-vous vraiment vous déconnecter ?"
        agree={{ text: "Se déconnecter", fn: auth.logout }}
        disagree={{ text: "Annuler", fn: () => 0 }}
      />
    </div>
  )
}
