import { useQuery } from "@tanstack/react-query"
import getLeads, { LeadsRequest } from "api/leads/getLeads"
import { KEY_LEADS } from "constants/queryKeys"
import { stripEmptyKeys } from "utils"

export default function useLeadsQuery(args: CustomQueryArgs<LeadsRequest>) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_LEADS, strippedKey] : [KEY_LEADS],
    queryFn: () => getLeads(strippedKey),
    ...args.options
  })
}
