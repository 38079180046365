import "static/styles/auth.scss"
import { useEffect, useState } from "react"
import { Button, CircularProgress, IconButton, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { LoginRequest } from "api/auth/login"
import { useDocumentTitle } from "usehooks-ts"
import useLoginMutation from "hooks/queries/auth/useLoginMutation"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Link as routerLink } from "react-router-dom"
import { URL_LOGIN } from "constants/urls"

export default function Login() {

  useDocumentTitle("Digital Leads | Connexion")

  const [showPassword, setShowPassword] = useState(false)

  const form = useForm<LoginRequest>({
    defaultValues: {
      username: "",
      password: ""
    }
  })

  function registerFields() {
    form.register("username", {
      required: "Merci de fournir votre nom d'utilisateur",
    })

    form.register("password", {
      required: "Merci de fournir votre mot de passe"
    })
  }

  registerFields()

  const loginMutation = useLoginMutation()

  const submitForm: SubmitHandler<LoginRequest> = (data) => {
    loginMutation.mutate(data)
  }

  // react-hook-form doesn't support enter button so we have to do it manually
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        loginMutation.mutate(form.getValues())
      }
    }
    window.addEventListener("keydown", handleKeyDown)

    // cleanup function to remove event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [loginMutation, form])

  return (
    <div className="login">
      <form onSubmit={form.handleSubmit(submitForm)}>
        <img className="logo" src={require("static/images/logo-full.png")} alt="Digital Leads" />

        <Controller
          name="username"
          control={form.control}
          render={(ctx) => (
            <TextField
              {...ctx.field}
              inputProps={{
                form: { autoComplete: "off" }
              }}
              variant="outlined"
              size="medium"
              fullWidth
              margin="normal"
              type="text"
              label="Nom d'utilisateur"
              error={!!ctx.fieldState.error}
              helperText={ctx.fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={form.control}
          render={(ctx) => (
            <TextField
              {...ctx.field}
              inputProps={{
                form: { autoComplete: "off" }
              }}
              InputProps={{
                style: { paddingRight: 0 },
                endAdornment: (
                  <InputAdornment position="end" sx={{ position: "absolute", right: 15 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="outlined"
              size="medium"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              error={!!ctx.fieldState.error}
              helperText={ctx.fieldState.error?.message}
            />
          )}
        />

        {loginMutation.isError && (
          <Typography sx={{ my: 2, color: "error.main" }}>Votre nom d'utilisateur ou votre mot de passe est incorrecte.</Typography>
        )}

        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 4, py: 3 }}
          disabled={loginMutation.isLoading}
        >
          {loginMutation.isLoading
            ? <CircularProgress size={20} color="inherit" />
            : "SE CONNECTER"
          }
        </Button>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 6, width: "100%" }}>
          <Link variant="body2" to={URL_LOGIN} component={routerLink}>Mot de passe oublié ?</Link>
        </Stack>
      </form>
    </div>
  )
}
