import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import updateLead, { UpdateLeadRequest } from "api/leads/updateLead"
import { KEY_LEADS } from "constants/queryKeys"

export default function useUpdateLeadMutation() {

  const queryClient = useQueryClient()
  const toastId = "update-lead"

  return useMutation({
    mutationFn: (data: UpdateLeadRequest) => updateLead(data),
    onMutate: () => {
      toast.loading("Modification du lead...", { id: toastId })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY_LEADS] })
      toast.success("Lead modifié avec succès", { id: toastId })
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de la modification du lead", { id: toastId })
    },
  })
}
