import { QueryClient } from "@tanstack/query-core"
import { AxiosError } from "axios"
import { toast } from "react-hot-toast"
import { Cookies } from "react-cookie"
import { COOKIE_ACCESS_TOKEN, COOKIE_CLIENT_ID, COOKIE_CLIENT_SECRET, COOKIE_REFRESH_TOKEN } from "constants/cookies"

const cookies = new Cookies()

const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME) * 1000,
      cacheTime: Number(process.env.REACT_APP_QUERY_CACHE_TIME) * 1000,
      onError: (err: AxiosError | any) => {
        const axiosError = err as AxiosError

        if (axiosError.response?.status === 500) {
          toast.error("Une erreur est survenue", {
            id: "globalError"
          })
        } else if (axiosError.response?.status === 401) {
          toast("Veuillez vous connecter", {
            id: "globalError"
          })

          cookies.remove(COOKIE_ACCESS_TOKEN)
          cookies.remove(COOKIE_CLIENT_SECRET)
          cookies.remove(COOKIE_CLIENT_ID)
          cookies.remove(COOKIE_REFRESH_TOKEN)
        } else if (axiosError.response?.status === 404) {
          toast.error("La ressource demandée n'existe pas", {
            id: "globalError"
          })
        }
      },
    },
    mutations: {
      retry: false,
      onError(err: AxiosError | any) {
        if (err.response?.status === 500) {
          toast.error("Une erreur est survenue", {
            id: "globalError"
          })
        } else if (err.response?.status === 401) {
          toast.error("Vous n'êtes pas autorisé à effectuer cette action", {
            id: "globalError"
          })
        }
      },
    },
  }
})

export default queryConfig
