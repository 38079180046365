import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import login, { LoginError, LoginRequest, LoginResponse } from "api/auth/login"
import { useCookies } from "react-cookie"
import { AxiosError } from "axios"
import { KEY_INFO_PROFILE } from "constants/queryKeys"
import { COOKIE_ACCESS_TOKEN, COOKIE_CLIENT_ID, COOKIE_CLIENT_SECRET, COOKIE_REFRESH_TOKEN } from "constants/cookies"

export default function useLoginMutation() {

  const [, setCookie] = useCookies([COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, COOKIE_CLIENT_SECRET, COOKIE_CLIENT_ID])
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: LoginRequest) => login(data),
    onSuccess: async (data: LoginResponse) => {
      const cookieOptions = {
        path: "/",
        sameSite: true,
        secure: true,
      }

      const accessTokenAge = Number(process.env.REACT_APP_ACCESS_TOKEN_MAX_AGE)
      const refreshTokenAge = Number(process.env.REACT_APP_REFRESH_TOKEN_MAX_AGE)

      // Only the access-token should have a much shorter max age than the other tokens
      setCookie(COOKIE_ACCESS_TOKEN, data.access_token, { ...cookieOptions, maxAge: accessTokenAge })
      setCookie(COOKIE_REFRESH_TOKEN, data.refresh_token, { ...cookieOptions, maxAge: refreshTokenAge })
      setCookie(COOKIE_CLIENT_SECRET, data.client_secret, { ...cookieOptions, maxAge: refreshTokenAge })
      setCookie(COOKIE_CLIENT_ID, data.client_id, { ...cookieOptions, maxAge: refreshTokenAge })

      // Invalidate the info-profile query to force a re-fetch
      await queryClient.invalidateQueries({ queryKey: [KEY_INFO_PROFILE] })
      toast.success(`Bienvenue ${data.username}`, { id: "auth" })
    },
    // Error handled in the component because it needs to be handled specifically for the login form
    // So we just attach the error type and pass it through to the component
    onError: (err: AxiosError<LoginError>) => err,
  })
}
