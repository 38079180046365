import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { compareVersions } from "compare-versions"
import { useEffect, useState } from "react"
import versions from "versions.json"

const currentVersion = process.env.REACT_APP_VERSION || "0.0.0"

export default function ReleaseNotes() {

  const [isOpen, setIsOpen] = useState(false)

  let previousVersion = localStorage.getItem("version")

  if (!previousVersion) {
    localStorage.setItem("version", "0.0.0")
    previousVersion = currentVersion.toString()
  }

  useEffect(() => {
    if (compareVersions(previousVersion || "0.0.0", currentVersion) < 0) {
      setIsOpen(true)
    }
  }, [previousVersion])

  const handleClose = () => {
    localStorage.setItem("version", currentVersion)
    setIsOpen(false)
  }

  const notes = versions as {[key: string]: string[]}

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 26 }}>
        Nouvelle mise à jour !
        <span style={{ color: "#666", fontSize: 20, fontWeight: 400 }}> (v{currentVersion})</span>
      </DialogTitle>
      <DialogContent>
        <ul>
          {notes[currentVersion].map((note: string, index: number) => (
            <li key={index} style={{ marginTop: 12 }}>
              {note}
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
