import { Route, Routes } from "react-router-dom"
import PublicRoutes from "./PublicRoutes"
import PrivateRoutes from "./PrivateRoutes"
import NotFound from "components/commun/NotFound"
import { URL_DASHBOARD, URL_LEADS, URL_LOGIN } from "constants/urls"
import Login from "components/auth/Login"
import Leads from "components/leads/Leads"
import Lead from "components/leads/Lead"
import { ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER } from "constants/roles"
import { Backdrop } from "@mui/material"
import Loader from "components/commun/Loader"
import useAuth from "hooks/useAuth"

export default function IndexRouter() {

  const auth = useAuth()

  if (auth.isLoading || auth.state === undefined) return (
    <Backdrop open={true}>
      <Loader />
    </Backdrop>
  )

  return (
    <Routes>
      <Route element={<PublicRoutes isAuth={auth.state} />}>
        <Route path={URL_LOGIN} element={<Login />} />
      </Route>

      <Route element={<PrivateRoutes
        isAuth={auth.state}
        userRole={ROLE_SUPERUSER}
        allowedRoles={[ROLE_MANAGER, ROLE_RESPONSABLE, ROLE_SUPERUSER]} />
      }>
        <Route path={URL_DASHBOARD} element={<Leads />} />
        <Route path={`${URL_LEADS}/:id`} element={<Lead />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
