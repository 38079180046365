import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_LEADS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type LeadsOrder = "-created_time" | "created_time" | "full_name" | "-full_name" | "email" | "-email" | "updated_at" | "-updated_at"

export type LeadsRequest = {
  page?: number
  page_size?: number
  secteur_activite?: string
  search_query?: string
  full_name?: string
  email?: string
  qualified?: boolean
  is_website?: boolean
  is_fb_lead?: boolean
  order?: LeadsOrder
  campaign_id?: number
  creation_date_gte?: string
  creation_date_lte?: string
  historique?: boolean
}

export type LeadsResponse = {
  id: number
  full_name: string
  email: string
  phone_number: string
  is_qualified: boolean
  qualification: string
  created_time: string
  updated_at: string
}

export type LeadsError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function getLeads(params: LeadsRequest) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_LEADS,
    params: params,
  })

  return response.data as Pagination<LeadsResponse>
}
