import axios from "axios"
import { API_LOGIN } from "constants/endpoints"

export type LoginRequest = {
  username: string
  password: string
}

export type LoginResponse = {
  id_user: number
  username: string
  groups: { name: string, permissions: Permissions }[]
  access_token: string
  client_id: string
  client_secret: string
  refresh_token: string
}

export type LoginError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function login(params: LoginRequest) {
  const response = await axios({
    method: "POST",
    url: API_LOGIN,
    data: params,
  })

  return response.data as LoginResponse
}
