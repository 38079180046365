import { useQuery } from "@tanstack/react-query"
import userDetails from "api/auth/userDetails"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { KEY_USER_DETAILS } from "constants/queryKeys"
import { useCookies } from "react-cookie"

export default function useUserQuery(args: CustomQueryArgs<undefined> = {}) {

  const [cookies] = useCookies([COOKIE_ACCESS_TOKEN])

  return useQuery({
    queryKey: [KEY_USER_DETAILS],
    queryFn: () => userDetails(),
    enabled: !!cookies[COOKIE_ACCESS_TOKEN],
    ...args.options
  })
}
