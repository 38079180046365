import { createTheme } from "@mui/material/styles"
import { frFR } from "@mui/material/locale"

const mainTheme = createTheme({
  spacing: 4,
  palette: {
    mode: "light",
    primary: {
      main: "#30085d",
      light: "#59397d",
      dark: "#210541",
    },
    secondary: {
      main: "#EAF0FF",
      light: "#f5f8ff",
      dark: "#cedbfb",
    },
    info: {
      main: "#2487F2",
      light: "#4f9ff4",
      dark: "#195ea9",
    },
    success: {
      main: "#3fa940",
      light: "#65ba66",
      dark: "#2c762c",
    },
    warning: {
      main: "#FBCB73",
      light: "#fbd58f",
      dark: "#af8e50",
    },
    error: {
      main: "#D93B69",
      light: "#e06287",
      dark: "#972949",
    },
  },
  typography: {
    fontFamily: "'Work Sans Variable', roboto",
    htmlFontSize: 16,
    h1: {
      fontSize: "4rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "3.5rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "2.5rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "2rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 600
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400
    },
    body2: {
      fontSize: "0.8rem",
      fontWeight: 300
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400
    },
    subtitle2: {
      fontSize: "0.8rem",
      fontWeight: 500
    },
    button: {
      textTransform: "uppercase"
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: "none",
          border: "1px solid #E7E9EF",
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          padding: "1rem",
          border: "1px solid #E7E9EF",
          boxShadow: "unset",
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: 4,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "0.8rem",
          textTransform: "unset",
          boxShadow: "none",
          padding: "0.5rem 1rem",

          "&:hover": {
            boxShadow: "none",
          }
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: "#003CC6",
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: "#E0E7FF",
          },
        },
      ],
    },
    MuiTabs: {
      variants: [
        {
          props: { orientation: "vertical" },
          style: {
            textAlign: "left",
            "& .MuiTabs-flexContainer": {
              flexDirection: "column",
            },
            "& .MuiTabs-indicator": {
              right: "unset",
              left: 0,
              width: 4,
            },
            "& .MuiTab-root": {
              alignItems: "flex-start",
              padding: "0.5rem 1rem",
              transition: "background-color 0.5s ease",
              "&:hover": {
                backgroundColor: "#EAF0FF",
              },
              "&.Mui-selected": {
                backgroundColor: "#EAF0FF",
                color: "#27245D",
                fontWeight: 600,
              },
              "&.MuiButtonBase-root": {
                maxWidth: "unset",
              }
            },
          },
        },
      ],
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          padding: "0.8rem 0.5rem",
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          border: "1px solid #CCCCCC"
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          style: {
            padding: "1rem"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        }
      },
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
        blurOnSelect: true,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: "0 1rem",
          "& thead th": {
            fontWeight: 600,
            fontSize: "0.8rem",
            border: 0,
            color: "#30085d",
            backgroundColor: "transparent",
            paddingTop: 0,
            paddingBottom: 0,
          },
          "& tbody tr": {
            fontSize: "0.8rem",
            backgroundColor: "#fff",
          },
          "& tbody td": {
            fontWeight: 400,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            borderTop: "1px solid #E7E9EF",
            borderBottom: "1px solid #E7E9EF",
            "&:first-of-type": {
              borderLeft: "1px solid #E7E9EF",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            },
            "&:last-child": {
              borderRight: "1px solid #E7E9EF",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            },
          },
        },
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: "1px solid #E7E9EF",
          borderRadius: 10,
          padding: 10,
          "&:before": {
            display: "none",
          },
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
          backgroundColor: "#fff",
          color: "#4B6983",
          borderRadius: 10,
          padding: "0.5rem 1rem",
          boxShadow: "0px 0px 10px #00000022",
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            borderRadius: 0,
            backgroundColor: "transparent",

            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#000",
              fontWeight: 600,
              boxShadow: "0 -3px 0 #000 inset",

              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },

          "& .MuiPaginationItem-page:hover": {
            backgroundColor: "#E7E9EF",
          },
        },
      },
    },
  },
}, frFR)

export default mainTheme
