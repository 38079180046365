import Chip from "@mui/material/Chip"
import { QUALIFICATIONS } from "constants/data"

type Props = {
  qualification: Qualification
}

export default function QualificationChip(props: Props) {

  const { qualification } = props

  if (qualification === "chaud") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#FFD7D9", color: "#A2191F" }}
    />
  )

  if (qualification === "interesse") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#A7F0BA", color: "#0E6027" }}
    />
  )

  if (qualification === "rappel") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#9EF0F0", color: "#005D5D" }}
    />
  )

  if (qualification === "sans_reponse") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#E8DAFF", color: "#6929C4" }}
    />
  )

  if (qualification === "pas_interesse") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#f1c8ea", color: "#bc25a2" }}
    />
  )

  if (qualification === "demande_d_information") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#ffe3ce", color: "#b45e1a" }}
    />
  )

  if (qualification === "fausses_coordonnees") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#e0d4ff", color: "#3f199d" }}
    />
  )

  if (qualification === "doublon") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      variant="filled"
      sx={{ bgcolor: "#caecff", color: "#347a9f" }}
    />
  )

  if (qualification === "indefini") return (
    <Chip
      label={QUALIFICATIONS.find(q => q.id === qualification)?.name}
      color="default"
      variant="filled"
    />
  )

  return null
}
