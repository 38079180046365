// auth
export const KEY_TEST_TOKEN = "test-token"
export const KEY_INFO_PROFILE = "info-profile"
export const KEY_USER_DETAILS = "user-details"

// leads
export const KEY_LEADS = "leads"

// data
export const KEY_VILLES = "villes"
export const KEY_SECTEURS_ACTIVITE = "secteurs-activite"

// Contacts
export const KEY_CONTACTS = "contacts"

// Campagnes
export const KEY_CAMPAGNES = "campagnes"

