import "static/styles/commun/header.scss"
import { Container } from "@mui/material"
import { Link } from "react-router-dom"
import { URL_DASHBOARD } from "constants/urls"
import User from "./components/User"

export default function Header() {

  return (
    <header className="header">
      <Container maxWidth="xl">
        <div className="header-inner">
          <Link to={URL_DASHBOARD}>
            <img className="logo" src={require("static/images/logo-full.png")} alt="" />
          </Link>

          <User />
        </div>
      </Container>
    </header>
  )
}
