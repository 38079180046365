export const URL_LOGIN = "/login"
export const URL_DASHBOARD = "/"
export const URL_LEADS = "/leads"
export const URL_ACTIFS = "/actifs"
export const URL_EXPERTISES = "/expertises"
export const URL_FORMULAIRES = "/formulaires"
export const URL_RAPPORTS = "/rapports"

export const URL_SANDBOX = "/sandbox"
export const URL_BUILDER = "/sandbox/builder"
export const URL_RENDERER = "/sandbox/renderer"
export const URL_REPORTER = "/sandbox/reporter"
