
export const QUALIFICATIONS: { id: Qualification, name: string }[] = [
  { id: "chaud", name: "Chaud" },
  { id: "interesse", name: "Intéressé" },
  { id: "rappel", name: "Rappel" },
  { id: "sans_reponse", name: "Sans reponse" },
  { id: "pas_interesse", name: "Pas intéressé" },
  { id: "demande_d_information", name: "Demande d'information" },
  { id: "fausses_coordonnees", name: "Fausses coordonnées" },
  { id: "doublon", name: "doublon" },
  { id: "indefini", name: "Indéfini" },
]

export const SEXE = [
  { id: "homme", name: "Homme" },
  { id: "femme", name: "Femme" },
]

export const MODE = [
  { id: "a credit", name: "A credit" },
  { id: "comptant", name: "Comptant" },
]
