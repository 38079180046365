import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_USER_DETAILS } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type UserDetailsResponse = {
  id_user: number
  username: string
  email: string
  first_name: string
  last_name: string
  groups: {
    name: string
    permissions: string[]
  }[]
}

export default async function userDetails() {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_USER_DETAILS,
  })

  return response.data as UserDetailsResponse
}
