import { useQuery } from "@tanstack/react-query"
import getLead, { LeadRequest } from "api/leads/getLead"
import { KEY_LEADS } from "constants/queryKeys"
import { stripEmptyKeys } from "utils"

export default function useLeadQuery(args: CustomQueryArgs<LeadRequest>) {

  const strippedKey = stripEmptyKeys(args.key)

  return useQuery({
    queryKey: args.key ? [KEY_LEADS, strippedKey] : [KEY_LEADS],
    queryFn: () => getLead(strippedKey),
    ...args.options
  })
}
