import { format } from "date-fns"

// Strips empty params from a query object and returns a new object
export function stripEmptyKeys<Type>(params: Type | undefined) {
  const paramsClone = { ...params }

  Object.keys(paramsClone).forEach(key => {
    if (
      paramsClone[key as keyof typeof params] === ""
      || paramsClone[key as keyof typeof params] === undefined
      || paramsClone[key as keyof typeof params] === null
    ) {
      delete paramsClone[key as keyof typeof params]
    }
  })

  return paramsClone as Type
}

export function standardDateFormat(date: string): string {
  return format(new Date(date), "dd/MM/yyyy")
}

export function standardTimeFormat(date: string): string {
  return format(new Date(date), "HH:mm")
}

export function pagesCount(count: number, countPerPage: number) {
  if (countPerPage > 0 && count > 0) {
    return Math.ceil(count / countPerPage)
  }
  return 0
}
