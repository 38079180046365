import { useQuery } from "@tanstack/react-query"
import getVilles from "api/data/villes"
import { KEY_VILLES } from "constants/queryKeys"

export default function useVillesQuery(args: CustomQueryArgs<undefined> = {}) {
  return useQuery({
    queryKey: [KEY_VILLES],
    queryFn: () => getVilles(),
    ...args.options
  })
}
