const baseURL = process.env.REACT_APP_API_URL

// Auth
export const API_LOGIN = `${baseURL}/login/`
export const API_USER_DETAILS = `${baseURL}/user-details/`
export const API_TEST_TOKEN = `${baseURL}/profile/testtoken/`
export const API_REFRESH_TOKEN = `${baseURL}/profile/refreshtoken/`

// Leads
export const API_LEADS = `${baseURL}/leads/`

// Campagnes
export const API_CAMPAGNES = `${baseURL}/campagnes/`

// Contacts
export const API_CONTACTS = `${baseURL}/liste-contacts/`
export const API_CREATE_CONTACT = `${baseURL}/create-contact/`

// Secteurs activite
export const API_SECTEURS_ACTIVITES = `${baseURL}/secteurs-activites/`

// Villes
export const API_VILLES = `${baseURL}/villes/`
