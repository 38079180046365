import { Box } from "@mui/material"
import BarLoader from "react-spinners/BarLoader"

type Props = {
  padding?: number
}

export default function Loader({ padding }: Props) {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p: padding || 4
    }}>
      <BarLoader
        color="#30085d"
        height={4}
        speedMultiplier={1}
        width={100}
      />
    </Box>
  )
}
